<template>
  <div>
    <div style="width:100%;text-align: left;">
        <div class="labInfo">
            <label>年级：</label>
            <div class="testInfo">
              <p :class="idxOne == 0?'spanInfo':''" @click="btnInfoOne(0)">全部</p>
              <p v-for="gradeLists in gradeList" :key="gradeLists.id" @click="btnInfoOne(gradeLists.id)"
            :class="idxOne == gradeLists.id?'spanInfo':''">{{gradeLists.gradeName}}</p>
            </div>
        </div>
        <div class="labInfo">
            <label>类别：</label>
            <div class="testInfo">
              <p :class="idxTwo == 0?'spanInfo':''" @click="btnInfoTwo(0)">全部</p>
              <p v-for="cateLists in cateList" :key="cateLists.id" @click="btnInfoTwo(cateLists.id)"
            :class="idxTwo == cateLists.id?'spanInfo':''">{{cateLists.categoryName}}</p>
            </div>
        </div>
        <div class="labInfo">
            <label>年份：</label>
            <div class="testInfo">
              <p :class="idxThree == 0?'spanInfo':''" @click="btnInfoThree(0)">全部</p>
              <p v-for="item in yearList" :key="item.id" @click="btnInfoThree(item.id)"
              :class="idxThree == item.id?'spanInfo':''">{{item.yearNo}}</p>
            </div>
        </div>
        <div class="labInfo">
            <label>城区：</label>
            <div class="testInfo">
              <p :class="idxFour == 0?'spanInfo':''" @click="btnInfoFour(0)">全部</p>
              <p v-for="cityLists in cityList" :key="cityLists.id" @click="btnInfoFour(cityLists.id)"
            :class="idxFour == cityLists.id?'spanInfo':''">{{cityLists.cityName}}</p>
            </div>
        </div>
        <div class="labInfo">
            <label>排序：</label>
            <div class="testInfo">
              <p v-for="sequenceLists in sequenceList" :key="sequenceLists.id" @click="btnInfo(sequenceLists.id)"
            :class="idx == sequenceLists.id?'spanInfo':''">{{sequenceLists.name}}</p>
            </div>
        </div>
    </div>
    <el-empty description="暂无数据" v-if="coursewareList.length==0" />
    <div v-loading="loading" element-loading-text="数据加载中..."
    v-for="(item, index) in coursewareList" :key="index" class="contentInfo">
        <el-row @click="gotoPage(item.id, item.subjectId)">
            <el-col :span="12" style="text-align: left;">
                <span class="titleInfo">{{item.subjectName.slice(0, 1)}}</span>
                <span style="font-size:13px;">{{item.coursewareName}}</span>
            </el-col>
            <el-col :span="12" style="text-align: right;">
              <span v-if="item.originalPrice==0" style="font-size:13px;color:red">免费</span>
              <div v-if="item.originalPrice!=0">
                <p style="font-size:15px;color:#fa6c13;font-weight:bold">￥{{item.originalPrice}}</p>
                <p v-if="item.memberFree==1" style="font-size:13px;color:orange;margin-top:-13px">会员免费</p>
              </div>
            </el-col>
            <el-col :span="12" style="text-align: left;color:#a4a3a3;font-size:10px">
                <span>浏览量：</span>
                <span>{{item.previewNum}}</span>
                <span style="margin-left:30px">下载量:</span>
                <span>{{item.downloadNum}}</span>
            </el-col>
            <el-col :span="12" style="text-align: right;color:#a4a3a3;font-size:10px">
                <span>上传时间：{{item.createTime}}</span>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12" style="text-align: left;display: flex;justify-content: flex-start;">
              <p @click="addCollect(item.id)" v-if="item.collect==0" style="margin-right:20px">
                <el-icon :size="13" style="top:2px" >
                  <Star color="#a4a3a3" />
                </el-icon>
                <span style="font-size:10px;color:#a4a3a3;">收藏</span>
              </p>
              <p @click="cancellCollect(item.id)" v-if="item.collect==1" style="margin-right:20px">
                <el-icon :size="13" color="#ffbe6e" style="top:2px">
                  <StarFilled />
                </el-icon>
                <span style="font-size:10px;">已收藏</span>
              </p>
              <p @click="addInformation(item.id)" v-if="item.addCart==0 && item.originalPrice!=0">
                <el-icon :size="13" :color="color" style="top:3px" >
                <ShoppingCart color="#a4a3a3" />
              </el-icon>
                <span style="font-size:10px;color:#a4a3a3;">加资料蓝</span>
              </p>
              <p @click="cancellInformation(item.id)" v-if="item.addCart==1 && item.originalPrice!=0">
                <el-icon :size="13" color="#ffbe6e" style="top:3px" >
                <ShoppingCartFull />
              </el-icon>
                <span style="font-size:10px;">已加资料蓝</span>
              </p>
              <!-- <p @click="FXBtn(item.coursewareName)">
                <el-icon :size="16" style="top:3px" >
                  <Position />
                </el-icon>
                <span style="font-size:13px;">分享</span>
              </p> -->
            </el-col>
            <el-col :span="12" style="text-align: right;">
              <span class="buttonInfoOne" @click="collectBtn(item)">下载</span>
            </el-col>
        </el-row>
    </div>
    <div style="padding: 6px 0;width:100%;display: flex;justify-content: center;">
      <el-pagination v-model:current-page="pageInfo.pageCurrent" v-model:page-size="pageInfo.pageSize"
      :page-sizes="[10, 20, 50, 100]" :small="small" :background="background"
      layout="total, sizes, prev, pager, next, jumper" :total="pageInfo.pageTatol" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" />
    </div>
    <el-dialog v-model="dialogTableVisible" :title="fromInfoTitle" align-center
    style="text-align:left;min-width: 460px;">
      <div class="diaImg">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane v-for="(item,index) in DownloadList" :key="item.fileType" :label="item.fileTypeName" :name="index">
            <el-empty description="暂无下载文件" v-if="fileInfo.length == 0" />
            <li v-for="items in fileInfo" :key="items.id" @click="downBtn(items.id)">
              <img src="../assets/pdf.jpg" v-show="item.fileTypeName=='pdf'" />
              <img src="../assets/word.jpg" v-show="item.fileTypeName=='word'" />
              <img src="../assets/ppt.jpg" v-show="item.fileTypeName=='ppt'" />
              <img src="../assets/zip.jpg" v-show="item.fileTypeName=='zip'" />
              <p>{{items.fileName}}</p>
            </li>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FileSaver from "file-saver"
import { ElMessage } from 'element-plus'
import {getYearList, getGrade, getCategoryList, getCityList, getCoursewareList, addCollet, removeCollet,
addShopCars, removeShopCars, downloadCousewara, getDownloadInfo, upCousewaraPreview, upCousewaraDownload} from '@/http/api'

export default {
  name: 'TestpaperVue',
  props: ['filesData', 'datas', 'gradeId', 'dataName'],
  data() {
    return{
      loading: false,
      activeName: 0,
      dialogTableVisible: false,
      yearList: [],
      gradeList: [],
      cateList: [],
      cityList: [],
      coursewareList: [],
      sequenceList: [
        { id: 0, name: '综合' },
        { id: 1, name: '浏览' },
        { id: 2, name: '下载' },
        { id: 3, name: '时间' },
      ],
      fromInfoTitle: '',
      idx: 0,
      idxOne: '',
      idxTwo: '',
      idxThree: '',
      idxFour: '',
      fromInfo: {},
      allList: [],
      downloadType: '',
      pageInfo: {
        pageSize: 10,
        pageCurrent: 1,
        pageTatol: null,
      },
      DownloadList: [],
      fileInfo: [],
      downIds: '',
    }
  },
  created() {
    this.getCateList() // 查询类别列表根据栏目id
    this.getList() // 查询年份
    this.getCity() // 查询城市
    this.getGradeList() // 获取年级
    this.getCourseware() // 查询课件列表
  },
  watch:{
    // 科目id
    filesData(newValue,oldValue){
      if (newValue) {
        this.getCourseware() // 查询课件列表
      }
    },
    //  栏目id
    datas(newValue,oldValue){
      if (newValue) {
        this.getCateList() // 查询类别
        this.getCity() // 查询城市
        this.getCourseware() // 查询课件列表
      }
    },
    //  学段id
    gradeId(newValue,oldValue){
      if (newValue) {
        this.getGradeList() // 查询年级
      }
    },
  },
  methods:{
    btnInfo(id) {
      this.idx = id
      this.loading = true
      this.getCourseware()
    },
    btnInfoOne(id) {
      if (id == 0) {
        this.idxOne = ''
      } else {
        this.idxOne = id
      }
      this.loading = true
      this.getCourseware()
    },
    btnInfoTwo(id) {
      if (id == 0) {
        this.idxTwo = ''
      } else {
        this.idxTwo = id
      }
      this.loading = true
      this.getCourseware()
    },
    btnInfoThree(id) {
      if (id == 0) {
        this.idxThree = ''
      } else {
        this.idxThree = id
      }
      this.loading = true
      this.getCourseware()
    },
    btnInfoFour(id) {
      if (id == 0) {
        this.idxFour = ''
      } else {
        this.idxFour = id
      }
      this.loading = true
      this.getCourseware()
    },
    gotoPage(id, subjectId) {
      upCousewaraPreview(id).then(ress=>{
        if (ress.data.code==200) {
          const { href } = this.$router.resolve({
            path: '/about',
            query: {ids: id, subIds: subjectId, from: this.dataName}
          });
          window.open(href, "_blank");
        }
      })
    },
    getList() {
      this.yearList = []
      getYearList().then(res=>{
        this.yearList = res.data.data
      }).catch(err=>{})
    },
    // 获取年级
    getGradeList() {
      this.gradeList = []
      getGrade(this.gradeId).then(res=>{
        this.gradeList = res.data.data
      }).catch(err=>{})
    },
    getCateList() {
      this.cateList = []
      getCategoryList(this.datas).then(res=>{
        this.cateList = res.data.data
      }).catch(err=>{})
    },
    getCity() {
      this.cityList = []
      getCityList(this.datas).then(res=>{
        this.cityList = res.data.data
      }).catch(err=>{})
    },
    // 查询数据列表
    getCourseware() {
      this.coursewareList = []
      let data = {
        coursewareName: sessionStorage.getItem('serchInfo'), // 搜索名字
        columnId: this.datas, // 栏目id
        categoryId: this.idxTwo, // 类别id
        gradeId: this.idxOne, // 年级id
        yearId: this.idxThree, // 年份id
        subjectId: this.filesData, // 科目id
        cityId: this.idxFour, // 城区id
        sort: this.idx, // 排序规则
        page: this.pageInfo.pageCurrent, // 分页条数
        pageSize: this.pageInfo.pageSize, // 分页条数
      }
      getCoursewareList(data).then(res=>{
        if (res.data.code==200) {
          this.coursewareList = res.data.data.rows
          this.pageInfo.pageTatol = res.data.data.total
          this.loading = false
        }
      }).catch(err=>{})
    },
    // 下载
    collectBtn(item) {
      this.downIds = item.id
      if (item.originalPrice!=0) {
        this.allList.push(item)
        sessionStorage.setItem('payList', JSON.stringify(this.allList))
        const { href } = this.$router.resolve({
          path: '/settiement',
        });
        window.open(href, "_blank");
      } else {
        this.fromInfoTitle = item.coursewareName
        this.dialogTableVisible = true
        this.getDownloadInfos()
      }
    },
    getDownloadInfos() {
      this.DownloadList = []
      getDownloadInfo(this.downIds).then(res=>{
        if (res.data.code==200) {
          this.DownloadList = res.data.data
          this.fileInfo = res.data.data[0].fileList
          this.activeName = res.data.data[0].indexInfo
        }
      })
    },
    handleClick(tab) {
      let indexInfo = tab.props.name
      this.fileInfo = this.DownloadList[indexInfo].fileList
    },
    // 添加课件收藏
    addCollect(id) {
      let arrayt = []
      arrayt.push(id)
      addCollet(arrayt).then(res=>{
        if (res.data.code == 200) {
          ElMessage({
            message: res.data.message,
            type: 'success',
          })
          this.getCourseware()
        }
      }).catch(err=>{})
    },
    // 取消课件收藏
    cancellCollect(id) {
      removeCollet(id).then(res=>{
        if (res.data.code == 200) {
          ElMessage({
            message: res.data.message,
            type: 'success',
          })
          this.getCourseware()
        }
      }).catch(err=>{})
    },
    // 添加资料蓝
    addInformation(id) {
      addShopCars(id).then(res=>{
        if (res.data.code == 200) {
          ElMessage({
            message: res.data.message,
            type: 'success',
          })
          this.getCourseware()
        }
      }).catch(err=>{})
    },
    // 取消资料蓝
    cancellInformation(id) {
      removeShopCars(id).then(res=>{
        if (res.data.code == 200) {
          ElMessage({
            message: res.data.message,
            type: 'success',
          })
          this.getCourseware()
        }
      }).catch(err=>{})
    },
    downBtn(ids) {
      const that = this
      // 增加课件下载量
      upCousewaraDownload(that.downIds).then(ress=>{
        if (ress.data.code==200) {
          console.log("成功！")
        }
      })
      // 下载课件
      downloadCousewara(ids).then(res=>{
        if (res.data.code==200) {
          let downloadUrl = res.data.data
          const lastIndex = downloadUrl.lastIndexOf('.');
          if (lastIndex === -1) {
            console.log(downloadUrl)
          } else {
            that.downloadType=downloadUrl.slice(lastIndex + 1)
          }
          if (that.downloadType=='pdf') {
            let oReq = new XMLHttpRequest();
            let URLToPDF = downloadUrl
            oReq.open("GET", URLToPDF, true);
            oReq.responseType = "blob";
            oReq.onload = function () {
              let file = new Blob([oReq.response], {
                type: "application/pdf",
              });
              FileSaver.saveAs(file, that.fromInfoTitle+".pdf");
            };
            oReq.send()
          } else {
            window.open(downloadUrl)
          }
        }
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.labInfo{
  width:100%;border-bottom: 1px dashed #cbcaca;
  display: flex;justify-content: flex-start;padding-top: 10px;
  .testInfo{
    display: flex;flex-wrap: wrap;
    p{
      font-size: 10px;padding: 0 10px;cursor: pointer;
      margin-bottom: 10px;
    }
  }
}
label{
  font-size: 10px;color: #a4a3a3;white-space: nowrap;
}
.contentInfo{
  border-bottom:1px solid #cbcaca;padding:5px 0 8px 0;line-height:35px;
  cursor: pointer;
}
.buttonInfoOne{
  background: #3c7cfb;padding: 7px 30px;
  color: white;border-radius: 5px;font-size: 13px;
  cursor: pointer;
}
.titleInfo{
  background: burlywood;color: white;font-size: 13px;
  padding: 3px;border-radius: 3px;margin-right: 5px;
}
.titleInfoOne{
  background: red;color: white;font-size: 0.1rem;
  padding: 2px;border-radius: 2px;margin-left: 10px;
}
.titleInfoTwo{
  background: orange;color: white;font-size: 0.1rem;
  padding: 2px;border-radius: 2px;margin-left: 5px;
}
.spanInfo{
  color: blue;
}
.diaImg{
  margin-top: -20px;
}
.diaImg .demo-tabs{
  width: 100%;
}
.diaImg li{
  width: 90%;display: flex;justify-content: flex-start;
  border: 1px solid #aaaaaa;border-radius: 5px;
  padding: 10px 4%;align-items: center;margin-top: 10px;
  cursor: pointer;
}
.diaImg li img{
  width: 50px;height: 50px;background-size: 100% 100%;
  margin-right: 10px;
}
</style>
