import axios from 'axios'
import { ElMessage } from 'element-plus'

export const Service = axios.create({
    baseURL: 'https://kj.api.bjchuxinjiaoyu.com/api/',
    timeout: 5000,
    headers: {
        'Content-Type':'application/json;charset=UTF-8'
    }
})
Service.interceptors.request.use(config => {
    config.headers.platform = 'pc'
    if (localStorage.getItem('token')) {
        config.headers.Authorization = localStorage.getItem('token')
    }
    return config
}, error => {
    return Promise.reject(error)
})
// 响应拦截器
Service.interceptors.response.use(response => {
    console.log(response.data.code)
    if (response.data.code==401) {
        localStorage.setItem('token', '')
        ElMessage(response.data.message)
    }
    return response
}, error => {
    return Promise.reject(error)
})


