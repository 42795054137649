<template>
  <div class="home">
    <!-- 头部 -->
    <div class="headerInfo">
      <div class="serchInfo">
        <div class="imgInfo">
          <img src="../assets/logo.png" />
          <div class="titleInfos">
            <p>初心题库</p>
          </div>
        </div>
        <div style="margin-top:2.5px">
          <el-input v-model="inputs" placeholder="请输入关键字" class="inputInfo"></el-input>
          <el-button type="primary" class="buttonItem" @click="serchBtn(inputs)">
            <el-icon style="vertical-align: middle">
              <Search color="#a3a3a3" />
            </el-icon>
          </el-button>
        </div>
        <div class="searchItem">
            <label style="color:white">热门搜索：</label>
            <span v-for="(item, index) in fromList" :key="index" @click="serchBtn(item)">{{item}}</span>
        </div>
      </div>
    </div>
    <div class="mainInfo">
      <div class="selectInfo">
        <el-popover :visible="visibleInfo" placement="bottom" :width="300" trigger="click">
          <template #reference>
            <el-button @click="visibleInfo = !visibleInfo" type="primary" style="width:120px;height:40px">
              {{titleInfo}}<el-icon class="el-icon--right"><Switch /></el-icon>
            </el-button>
          </template>
          <div>
            <div v-for="(stuLists, index) in stuList" :key="index" class="colItem">
              <p>{{stuLists.phaseName}}</p>
              <el-row class="lineItem">
                <el-col :span="4" v-for="(subjectLists, indexOne) in stuLists.subjectList"
                :key="indexOne" style="cursor: pointer;"
                @click="btnSubject(stuLists.studyPhaseId,subjectLists.subjectId,stuLists.phaseName,subjectLists.subjectName)">
                <span :class="ids==subjectLists.subjectId?'active':''">{{subjectLists.subjectName}}</span></el-col>
              </el-row>
            </div>
          </div>
        </el-popover>
      </div>
      <div style="position: relative;z-index: 999;">
        <el-popover :visible="visible" placement="bottom" :width="100" trigger="click" v-if="loginShow">
          <template #reference>
            <div @click="visible = !visible" class="userInfo">
              <img :src="userForm.avatarUrl" />
              <span>{{userForm.nickName}}</span>
              <CaretBottom style="width: 1em;height: 1em;margin-top:6px" />
            </div>
          </template>
          <div style="text-align:center;color:red" @click="outBtn">
            <SwitchButton color="red" style="width: 1.5em;height: 1.5em;" />
            <p style="font-size:14px">退出登录</p>
          </div>
        </el-popover>
      </div>
      <div v-if="!loginShow" class="loginInfo" @click="loginBtn">登录</div>
      <div>
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane v-for="(colLists,index) in colList" :key="index" :label="colLists.columnName" :name="colLists.id">
            <PreferredVue ref="preInfo" :filesData="filesData" :datas="datas" :dataName="dataName"
            v-if="colLists.templateType == '1'"></PreferredVue>
            <TestpaperVue ref="tesInfo" :filesData="filesData" :datas="datas" :gradeId="gradeId" :dataName="dataName"
            v-if="colLists.templateType == '0'"></TestpaperVue>
          </el-tab-pane>
          <el-tab-pane label="资料蓝" name="10">
            <InformationVue ref="mationInfo"></InformationVue>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 底部 -->
    <BottomInfoVue></BottomInfoVue>
    <!-- 侧边栏 -->
    <div class="asideInfo">
      <div class="sysInfo">
        <el-icon :size="size" :color="color">
          <Service />
        </el-icon>
        <p>客服</p>
      </div>
      <div class="sysInfo">
        <el-icon :size="size" :color="color">
          <Comment />
        </el-icon>
        <p>公众号</p>
      </div>
      <div class="sysInfo">
        <el-icon :size="size" :color="color">
          <Tickets />
        </el-icon>
        <p>平台介绍</p>
      </div>
      <div class="sysInfo" @click="gotoTop">
        <el-icon :size="size" :color="color">
          <Top />
        </el-icon>
        <p>回顶部</p>
      </div>
    </div>
    <!-- 登录弹窗 -->
    <el-dialog v-model="dialogPopVisible" width="400px">
      <div class="backImg">
        <img src="../assets/wx.png" />
        <p>微信扫码登录</p>
        <div id="weixin"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BottomInfoVue from '@/components/bottomInfo.vue'
import InformationVue from './Information.vue'
import PreferredVue from './Preferred.vue'
import TestpaperVue from './Testpaper.vue'
import {getStudyPhaseList, getColumuList, getLogin, logout, getUserInfo, wxLoginTest} from '@/http/api'
export default {
  name: 'HomeView',
  components: {
    BottomInfoVue,
    InformationVue,
    PreferredVue,
    TestpaperVue
  },
  data() {
    return {
      loginShow: false,
      visibleInfo: false,
      visible: false,
      dialogPopVisible: false,
      inputs: '',
      fromList: ['数学','北京','期末','上','初三'],
      ids: 0,
      titleInfo: '',
      size: 20,
      colList: [],
      stuList: [],
      activeName: 1,
      filesData: '',
      datas: '',
      gradeId: '',
      dataName: '',
      userForm: {},
    }
  },
  created() {
    if (localStorage.getItem('token')) {
      this.getUsers()
    } else {
      this.getCodeMes()
    }
    // this.getLoginTest()
    this.getStuList()
    this.getcolList()
  },
  methods: {
    getLoginTest() {
      wxLoginTest().then(res=>{
        localStorage.setItem('token', token)
        console.log(res.data.data.token)
      }).catch(err=>{})
    },
    gotoTop() {
      window.scrollTo(0,0)
    },
    // 获取登录二维码
    weixinClick() {
      new WxLogin({
        id: "weixin",
        appid: "wx5c1ec19eddcd63b8",
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent('https://pc.bjchuxinjiaoyu.com'),
        state: Math.ceil(Math.random() * 1000),
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDEzNXB4OyBtYXJnaW46IDBweDtib3JkZXI6IG5vbmV9Ci5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9Ci5pbXBvd2VyQm94IC5pbmZvIHt3aWR0aDogMTM1cHg7fQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9Ci5pbXBvd2VyQm94IHtyaWdodDogMjUlO30=",
      });
    },
    // 二维码弹窗
    loginBtn() {
      this.dialogPopVisible = true
      this.$nextTick(() => {
        this.weixinClick()
      })
    },
    getCodeMes() {
      let code = this.getQueryString("code");
      if (!code) {
      return 
      } else {
        this.getLoginMes(code);
      }
    },
    // 扫码登录获取地址返回code
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return "";
    },
    // 登录接口
    getLoginMes(code) {
      let data = {
        code: code
      }
      getLogin(data).then(res=>{
        if (res.data.code==200) {
          localStorage.setItem('token', res.data.data.token)
          this.dialogPopVisible = false
          this.getUsers()
        }
      }).catch(err=>{})
    },
    // 获取用户信息
    getUsers() {
      getUserInfo().then(res=>{
        if (res.data.code==200) {
          this.userForm = res.data.data
          this.loginShow = true
        }
      })
    },
    // 退出登录
    outBtn() {
      logout().then(res=>{
        if (res.data.code==200) {
          localStorage.setItem('token', '')
          this.$router.go(0)
        } else if(res.data.code==401) {
          localStorage.setItem('token', '')
          this.$router.go(0)
        }
      })
    },
    // 搜索
    serchBtn(item) {
      sessionStorage.setItem('serchInfo', item)
      this.$nextTick(() => {
        this.$refs.preInfo[0].getCourseware()
        this.$refs.tesInfo[0].getCourseware()
        sessionStorage.setItem('serchInfo', '')
      })
    },
    getStuList() {
      getStudyPhaseList().then(res=>{
        this.titleInfo = res.data.data[0].phaseName+res.data.data[0].subjectList[0].subjectName
        this.stuList = res.data.data
        this.ids = res.data.data[0].subjectList[0].subjectId
        this.filesData = res.data.data[0].subjectList[0].subjectId
        this.gradeId = res.data.data[0].studyPhaseId
      }).catch(err=>{})
    },
    // 查询栏目
    getcolList() {
      getColumuList().then(res=>{
        this.colList = res.data.data
        this.datas = res.data.data[0].id
        this.dataName = res.data.data[0].columnName
      }).catch(err=>{})
    },
    handleClick(tab) {
      this.activeName = tab.props.name
      this.datas = this.activeName
      this.dataName = tab.props.label
      if (tab.props.name==10) {
        if (localStorage.getItem('token')) {
          this.$nextTick(() => {
            this.$refs.mationInfo.getShopCars()
          })
        } else {
          this.dialogPopVisible = true
          this.$nextTick(() => {
            this.weixinClick()
          })
        }
      } else if (tab.props.name==1) {
        this.$nextTick(() => {
          this.$refs.tesInfo[0].getCateList()
          this.$refs.tesInfo[0].getCourseware()
        })
      } else if (tab.props.name==2) {
        this.$refs.preInfo[0].getAlbum()
        this.$refs.preInfo[0].getCateList()
        this.$refs.preInfo[0].getCourseware()
      } else if (tab.props.name==4) {
        this.$refs.preInfo[0].getAlbum()
        this.$refs.preInfo[0].getCateList()
        this.$refs.preInfo[0].getCourseware()
      }
    },
    btnSubject(studyPhaseId, subjectId, name, nameOne) {
      this.ids = subjectId
      this.filesData = subjectId
      this.gradeId = studyPhaseId
      this.titleInfo = name+nameOne
      this.visibleInfo = false
    },
  }
}
</script>
<style>
*{
  padding: 0;margin: 0;
}
.el-tabs__nav-wrap::after{
  background-color: white;
}
</style>
<style scoped lang="scss">
.home{
  width: 100%;padding: 0;background: #f5f7f9;
  min-width: 1200px;
  .headerInfo{
    width: 100%;background: #3c7cfb;padding: 10px 0;
    min-width: 1200px;
  }
  .serchInfo{
    width: 65%;margin: 0 auto;min-width: 1000px;
    display: flex;justify-content: space-between;
    .imgInfo{
      display: flex;
      justify-content: space-between;
      img{
        width: 50px;height: 50px;border-radius: 50%;background-size: 100% 100%;
      }
      .titleInfos{
        color: white;text-align: left;
        margin-left: 5px;margin-top: 10px;
      }
      .titleInfos p:nth-child(2){
        font-size: 14px;
      }
    }
  }
  .inputInfo{
      width: 400px;height: 45px;
  }
  .buttonItem{
      height: 45px;background: white;width: 60px;margin-left: 5px;
  }
  .searchItem{
      font-size: 13px;color: white;
      height: 45px;line-height:45px;margin-top:2.5px;
      span{
          margin-left: 8px;cursor: pointer;
      }
  }
}
.backImg{
  height:400px;
  img{
    width: 100%;height: 170px;background-size: 100% 100%;
  }
  p{
    font-size: 16px;font-weight: bold;
    color: black;
  }
  #weixin{
    height: 130px;width: 150px;
    overflow: hidden;margin: 10px auto 0;
  }
}
.mainInfo{
  width: 63%;margin: 10px auto;background: white;
  padding: 0 1%;min-width: 1000px;
}
.selectInfo{
  height:40px;width: 120px;
  float: left;position: relative;z-index: 99;margin-right: 20px;
}
.userInfo{
  width: 130px;float: right;padding-top: 5px;height: 32px;line-height: 32px;
  position: relative;z-index: 99;
  display: flex;justify-content: flex-start;
  img{
    width: 30px;height: 30px;border-radius: 50%;background-size: 100% 100%;
    cursor: pointer;
  }
  span{
    font-size: 13px;cursor: pointer;
  }
}
.loginInfo{
  background: #1859f0;color: white;width: 50px;height: 24px;
  line-height: 24px; text-align: center;font-size: 13px;
  border-radius: 12px;position: relative;float: right;margin-top: 5px;
  z-index: 99;cursor: pointer;
}
.asideInfo{
  position: fixed;right: 0;top: 40%;transform: translateY(-50px);
}
.sysInfo{
  background: #1859f0;width: 60px;padding: 20px 0;
  color: white;font-size: 12px;border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;margin-bottom: 10px;
}
.labInfo{
  width:100%;border-bottom: 1px dashed #cbcaca;
  padding: 10px 0;
}
.labInfo span{
  font-size: 13px;padding: 0 10px;
}
label{
  font-size: 13px;color: #a4a3a3;
}
.contentInfo{
  border-bottom:1px solid #cbcaca;padding:5px 0 8px 0;line-height:30px;
  cursor: pointer;
}
.titleInfo{
  background: burlywood;color: white;font-size: 13px;
  padding: 3px;border-radius: 3px;margin-right: 5px;
}
.titleInfoOne{
  background: red;color: white;font-size: 0.1rem;
  padding: 2px;border-radius: 2px;margin-left: 10px;
}
.titleInfoTwo{
  background: orange;color: white;font-size: 0.1rem;
  padding: 2px;border-radius: 2px;margin-left: 5px;
}
.colItem{
  p{
    font-size:16px;margin: 15px 0 5px;font-size: 15px;
    font-weight: 500;
    color: #333;font-family: PingFangSC-Medium,PingFang SC;
  }
  .lineItem{
    font-size: 12px;line-height: 23px;font-family: PingFangSC-Medium,PingFang SC;
    color: #666;
    span{
      padding: 0 2px;
    }
  }
}
.active{
  padding: 0 2px;background-color: #3c7cfb;border-radius: 15px;
  color: white;font-size: 12px;font-family: PingFangSC-Medium,PingFang SC;
}
.buttonInfoOne{
  background: #3c7cfb;padding: 7px 30px;
  color: white;border-radius: 5px;font-size: 13px;
  cursor: pointer;
}
</style>
