import { Service } from "./Service";


// 微信登录
export function getLogin(data) {
    return Service({
        url: 'courseware/kjUser/wxLogin',
        method: 'post',
        data: data
    })
}
// 退出登录
export function logout() {
    return Service({
        url: 'courseware/kjUser/logout',
        method: 'get',
    })
}
export function wxLoginTest() {
    return Service.post('courseware/kjUser/wxLoginTest')
}
// 查询用户详情
export function getUserInfo() {
    return Service.get('courseware/kjUser/getUserInfo')
}
// 获取年份
export function getYearList() {
    return Service.get('courseware/kjYears/getYearList')
}
// 获取城区
export function getCityList(cityId) {
    return Service({
        url: 'courseware/kjCity/getCityList',
        method: 'get',
        params: { columnId: cityId }
    })
}
// 获取栏目
export function getColumuList() {
    return Service.get('courseware/kjColumn/getColumuList')
}
// 获取类别
export function getCategoryList(colInfo) {
    return Service({
        url: 'courseware/kjCategory/getCategoryList',
        method: 'get',
        params: { columnId: colInfo }
    })
}
// 查询学段列表和科目
export function getStudyPhaseList() {
    return Service.get('courseware/kjStudyPhase/getStudyPhaseList')
}
// 获取年级
export function getGrade(idInfo) {
    return Service({
        url: 'courseware/kjGrade/getGradeList',
        method: 'get',
        params: { studyPhaseId: idInfo }
    })
}
// 获取专辑
export function getAlbumList(data) {
    return Service({
        url: 'courseware/kjAlbum/getAlbumList',
        method: 'get',
        params: data
    })
}
// 查询课件列表
export function getCoursewareList(data) {
    return Service({
        url: 'courseware/kjCourseware/getCoursewareList',
        method: 'get',
        params: data
    })
}
// 查询课件详情
export function getCoursewareInfo(courId) {
    return Service({
        url: 'courseware/kjCourseware/getCoursewareInfo',
        method: 'get',
        params: { coursewareId: courId}
    })
}
// 查询课件文件
export function getPreviewImages(imges) {
    return Service({
        url: 'courseware/kjCourseware/getPreviewImages/'+imges,
        method: 'get',
    })
}
// 获取下载文件列表
export function getDownloadInfo(courId) {
    return Service({
        url: 'courseware/kjCourseware/getDownloadInfo/'+courId,
        method: 'get',
    })
}
// 下载课件
export function downloadCousewara(fileId) {
    return Service({
        url: 'courseware/kjCourseware/getDownLoadUrl/'+fileId,
        method: 'get',
    })
}
// 查询我的收藏列表
export function getCollectList(data) {
    return Service({
        url: 'courseware/kjUserCollect/getCollectList',
        method: 'get',
        params: data
    })
}
// 添加课件收藏
export function addCollet(data) {
    return Service({
        url: 'courseware/kjUserCollect/addCollet',
        method: 'post',
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    })
}
// 删除课件收藏
export function removeCollet(coursewareId) {
    return Service({
        url: 'courseware/kjUserCollect/removeCollet/'+coursewareId,
        method: 'delete',
    })
}
// 查询我的资料栏（购物车）
export function getShopCarsList(data) {
    return Service({
        url: 'courseware/kjShopCars/getShopCarsList',
        method: 'get',
        params: data
    })
}
// 添加用户资料栏（购物车）
export function addShopCars(coursewareId) {
    return Service({
        url: 'courseware/kjShopCars/addShopCars/'+coursewareId,
        method: 'post',
    })
}
// 批量/单个删除课用户资料栏（购物车）
export function removeShopCars(coursewareIds) {
    return Service({
        url: 'courseware/kjShopCars/removeShopCars/'+coursewareIds,
        method: 'delete',
    })
}
// 增加课件预览量
export function upCousewaraPreview(coursewareId) {
    return Service({
        url: 'courseware/kjCourseware/upCousewaraPreview/'+coursewareId,
        method: 'put',
    })
}
// 增加课件下载量
export function upCousewaraDownload(coursewareId) {
    return Service({
        url: 'courseware/kjCourseware/upCousewaraDownload/'+coursewareId,
        method: 'put',
    })
}

// 查询会员套餐列表
export function getVipPackageList() {
    return Service.get('courseware/kjVipPackage/getVipPackageList')
}
// 创建课件订单
export function createCourseOrder(data) {
    return Service({
        url: 'courseware/kjOrder/createCourseOrder',
        method: 'post',
        data: data,
    })
}
// 微信支付订单
export function wxPayOrder(orderId, wxPayType) {
    return Service({
        url: 'courseware/kjOrder/wxPayOrder/'+orderId+'/'+wxPayType,
        method: 'post',
    })
}
// 账户支付订单
export function accountPayOrder(orderId) {
    return Service({
        url: 'courseware/kjOrder/accountPayOrder/'+orderId,
        method: 'post',
    })
}
// 创建充值订单
export function createTopUpOrder(amounts) {
    return Service({
        url: 'courseware/kjOrder/createTopUpOrder',
        method: 'post',
        data: {amount: amounts}
    })
}
// 创建购买会员套餐订单
export function createMealOrder(data) {
    return Service({
        url: 'courseware/kjOrder/createMealOrder',
        method: 'post',
        data: data,
    })
}
// 获取订单列表
export function getOrderList(data) {
    return Service({
        url: 'courseware/kjOrder/getOrderList',
        method: 'get',
        params: data
    })
}
// 支付状态
export function orderStatus(orderId) {
    return Service({
        url: '/courseware/kjOrder/getOrderInfo/'+orderId,
        method: 'get',
    })
}