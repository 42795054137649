<template>
  <div class="main">
    <el-footer class="titleInfo">
      <el-row>
        <el-col :span="12">初心教育 版权所有</el-col>
        <el-col :span="12">京ICP备2023016293号</el-col>
      </el-row>
      <p>由于初心资源网是一个信息分享及获取的平台，不确保部分用户上传资料的来源及知识产权归属。
        如您发现相关资料侵犯您的合法权益，请联系初心教育客服，我们核实后将及时进行处理。 </p>
    </el-footer>
  </div>
</template>

<script>
export default {
  name: 'bottomInfo',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main{
  width: 100%;padding: 15px 0;background: #efefef;
  min-width: 1200px;
  .titleInfo{
    width: 65%;margin: 0 auto;font-size: 15px;min-width: 1000px;
    p{
      font-size: 12px;margin-top: 10px;color: #adadad;
    }
  }
}

</style>
