<template>
  <div class="main">
    <ul class="ulOne">
        <li v-for="(item, index) in list" :key="index" :class="idx == item.id?'one':'two'"
        @click="changeBtn(item.id)">{{item.name}}</li>
    </ul>
    <!-- 资料蓝 -->
    <div class="zlInfo" v-show="idx == 0">
      <ul class="ulTwo">
        <el-empty description="暂无数据" v-if="carsList.length==0" />
        <li v-for="(carsLists, index) in carsList" :key="index">
          <div>
            <el-checkbox v-model="carsLists.checked" :id="carsLists.id" :value="carsLists" size="large"
            @change="btnCars(carsLists, index)" style="margin-right:10px;top:2px" />
            <span class="titleInfo">{{carsLists.subjectName.slice(0, 1)}}</span>
            <span>{{carsLists.coursewareName}}</span>
          </div>
          <div style="height:32px;" v-if="carsLists.originalPrice != 0">
            <p style="color:#fa6c13;font-weight:bold">￥:{{carsLists.originalPrice}}</p>
            <p style="color:orange">会员免费</p>
          </div>
          <div style="height:32px;line-height:32px" v-if="carsLists.originalPrice == 0">
            <p style="color:#fa6c13">免费</p>
          </div>
        </li>
      </ul>
      <div class="payinfo">
        <el-row>
          <el-col :span="12" style="display: flex;justify-content: flex-start;align-items: center;">
            <p><el-checkbox v-model="checkedall" size="large" style="margin-right:10px;" @change="allCheck" />全选</p>
            <p class="btnInfo" @click="removeSC"><Star style="width: 12px;height: 12px;margin-right: 2px" />移入收藏</p>
            <p class="btnInfo" @click="deleteInfo"><Delete style="width: 12px;height: 12px;margin-right: 2px" />删除所选</p>
          </el-col>
          <el-col :span="12" style="display: flex;justify-content: flex-end;align-items: center;">
            <p style="margin-right:25px;font-size:13px;">已选
              <span style="font-size:16px;color:#fa6c13;">{{allList.length}}</span>份资料</p>
            <p style="height:32px;font-size:13px;">
              <span>总计(不含税):<span style="color:#fa6c13;">￥{{allMoney}}</span></span><br>
              <span style="font-size:10px;">年会员折合价:￥0.00</span>
            </p>
            <p class="xdInfo" @click="placeOrder">立即下单</p>
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-show="idx == 1">
      <el-empty description="暂无数据" />
    </div>
    <!-- 收藏记录 -->
    <div v-show="idx == 2">
      <el-empty description="暂无数据" v-if="collectList.length==0" />
      <ul class="ulThree" v-loading="loading" element-loading-text="数据加载中...">
        <li v-for="(item, index) in collectList" :key="index">
          <p>
            <span class="titleInfo">{{item.subjectName.slice(0, 1)}}</span>
            <span>{{item.coursewareName}}</span>
          </p>
          <p @click="download(item)">下载</p>
        </li>
      </ul>
      <div style="width:100%;display: flex;justify-content: center;margin:10px 0">
        <el-pagination :current-page="pageInfo.pageCurrent" :page-size="pageInfo.pageSize"
        :page-sizes="[10, 20, 50]" :small="small" :background="background"
        layout="total, sizes, prev, pager, next, jumper" :total="pageInfo.pageTatol" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
      </div>
    </div>
    <el-dialog v-model="dialogTableVisible" :title="fromInfoTitle" align-center
    style="text-align:left;min-width: 460px;">
      <div class="diaImg">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane v-for="(item,index) in DownloadList" :key="item.fileType" :label="item.fileTypeName" :name="index">
            <el-empty description="暂无下载文件" v-if="fileInfo.length == 0" />
            <li v-for="items in fileInfo" :key="items.id" @click="downBtn(items.id)">
              <img src="../assets/pdf.jpg" v-show="item.fileTypeName=='pdf'" />
              <img src="../assets/word.jpg" v-show="item.fileTypeName=='word'" />
              <img src="../assets/ppt.jpg" v-show="item.fileTypeName=='ppt'" />
              <img src="../assets/zip.jpg" v-show="item.fileTypeName=='zip'" />
              <p>{{items.fileName}}</p>
            </li>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FileSaver from "file-saver"
import { ElMessage } from 'element-plus'
import {getCollectList, getShopCarsList, addCollet, removeShopCars,
getOrderList, downloadCousewara, getDownloadInfo, upCousewaraPreview, upCousewaraDownload} from '@/http/api'

export default {
  name: 'InformationVue',
  data() {
    return{
      dialogTableVisible: false,
      list: [
        { id: 0, name: '资料蓝' },
        { id: 1, name: '购买记录' },
        { id: 2, name: '收藏记录' },
      ],
      fromInfoTitle: '',
      idx: 0,
      pageInfo: { // 分页参数
        pageSize: 10,
        pageCurrent: 1,
        pageTatol: 10,
      },
      collectList: [], // 收藏列表
      carsList: [], // 资料栏列表
      orderList: [],
      changeList: [],
      checkedall: false,
      allList: [],
      downloadType: '',
      allMoney: 0,
      fileFrom: {},
      fileTypes: 0,
      DownloadList: [],
      fileInfo: [],
      downIds: '',
    }
  },
  // created() {
  //   this.getShopCars()
  // },
  methods:{
    changeBtn(id) {
      this.idx = id
      this.pageInfo.pageCurrent = 1
      this.pageInfo.pageSize = 10
      if (id == 0) {
        this.getShopCars()
      } else if (id == 2) {
        this.getCollect()
      }
    },
    // 资料栏列表
    getShopCars() {
      let data = {
        page: this.pageInfo.pageCurrent, // 分页条数
        pageSize: this.pageInfo.pageSize, // 分页条数
      }
      getShopCarsList(data).then(res=>{
        for(var i=0; i<res.data.data.length; i++){
          res.data.data[i].checked = false
        }
        this.carsList = res.data.data
      }).catch(err=>{})
    },
    // 全选
    allCheck() {
      this.allList = []
      this.allMoney = 0
      if (this.checkedall == true) {
        this.carsList.forEach(item => {
          item.checked = true;
          this.allMoney += item.originalPrice
          this.allList.push(item)
        })
      } else {
        this.carsList.forEach(item => {
          item.checked = false;
        })
      }
    },
    // 单选
    btnCars(item, index) {
      if (item.checked == true) {
        this.allList.push(item)
        this.allMoney = 0
        this.allList.forEach(item => {
          this.allMoney += item.originalPrice
        })
      } else {
        this.allList.splice(index, 1)
      }
      if (this.carsList.length == this.allList.length) {
        this.checkedall = true
      } else {
        this.checkedall = false
      }
    },
    // 移入收藏
    removeSC() {
      let idsInfo= []
      for (let i = 0; i < this.allList.length; i++) { 
        idsInfo.push(this.allList[i].id)
      }
      addCollet(idsInfo).then(res=>{
        if (res.data.code == 200) {
          ElMessage({
            message: res.data.message,
            type: 'success',
          })
        }
      }).catch(err=>{})
    },
    // 批量删除
    deleteInfo() {
      let idsInfos= []
      for (let i = 0; i < this.allList.length; i++) { 
        idsInfos.push(this.allList[i].id)
      }
      removeShopCars(idsInfos.join(',')).then(res=>{
        if (res.data.code == 200) {
          ElMessage({
            message: res.data.message,
            type: 'success',
          })
          this.getShopCars()
        }
      }).catch(err=>{})
    },
    // 收藏列表
    getCollect() {
      let data = {
        page: this.pageInfo.pageCurrent, // 分页条数
        pageSize: this.pageInfo.pageSize, // 分页条数
      }
      getCollectList(data).then(res=>{
        this.collectList = res.data.data.rows
        this.pageInfo.pageTatol = res.data.data.total
      }).catch(err=>{})
    },
    placeOrder() {
      if (this.allList.length==0) {
        ElMessage('请选择至少一条数据！')
      } else {
        sessionStorage.setItem('payList', JSON.stringify(this.allList))
        const { href } = this.$router.resolve({
          path: '/settiement',
        });
        window.open(href, "_blank");
      }      
    },
    // 订单列表
    getOrder() {
      let data = {
        page: this.pageInfo.pageCurrent, // 分页条数
        pageSize: this.pageInfo.pageSize, // 分页条数
      }
      getOrderList(data).then(res=>{
        this.orderList = res.data.data.rows
        this.pageInfo.pageTatol = res.data.data.total
      }).catch(err=>{})
    },
    // 收藏记录下载
    download(item) {
      this.downIds = item.id
      if (item.originalPrice!=0) {
        this.allList.push(item)
        sessionStorage.setItem('payList', JSON.stringify(this.allList))
        const { href } = this.$router.resolve({
          path: '/settiement',
        });
        window.open(href, "_blank");
      } else {
        this.fromInfoTitle = item.coursewareName
        this.dialogTableVisible = true
        this.getDownloadInfo()
      }
    },
    getDownloadInfo() {
      this.downList = []
      getDownloadInfo(this.downIds, this.fileTypes).then(res=>{
        if (res.data.code==200) {
          this.DownloadList = res.data.data
          this.fileInfo = res.data.data[0].fileList
          this.activeName = res.data.data[0].indexInfo
        }
      })
    },
    handleClick(tab) {
      let indexInfo = tab.props.name
      this.fileInfo = this.DownloadList[indexInfo].fileList
    },
    downBtn(ids) {
      const that = this
      downloadCousewara(ids).then(res=>{
        if (res.data.code==200) {
          let downloadUrl = res.data.data
          const lastIndex = downloadUrl.lastIndexOf('.');
          if (lastIndex === -1) {
            console.log(downloadUrl)
          } else {
            that.downloadType=downloadUrl.slice(lastIndex + 1)
          }
          if (that.downloadType=='pdf') {
            let oReq = new XMLHttpRequest();
            let URLToPDF = downloadUrl
            oReq.open("GET", URLToPDF, true);
            oReq.responseType = "blob";
            oReq.onload = function () {
              let file = new Blob([oReq.response], {
                type: "application/pdf",
              });
              FileSaver.saveAs(file, that.fromInfoTitle+".pdf");
            };
            oReq.send()
          } else {
            window.open(downloadUrl)
          }
        }
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main{
  .ulOne{
    display: flex;justify-content: flex-start;margin-bottom:15px;
    li{
      width: 70px;height: 25px;
      font-size: 13px;line-height: 25px;margin-right: 15px;
      cursor: pointer;
    }
    .one{
      background: #3c7cfb;color: white;border-radius: 5px;
    }
    .two{
      background: #dddfe2;color: #2c2c2c;border-radius: 5px;
    }
  }
  .zlInfo{
    height: 72vh;
    .payinfo{
      width:100%;border-top: 1px solid #e7e7e7;
      .btnInfo{
        border: 1px solid #b8b8b8;width: 90px;height: 24px;line-height: 24px;
        border-radius: 15px;font-size: 13px;margin-left: 10px;
        cursor: pointer;color: #4c4c4c;
      }
      .xdInfo{
        height: 55px;line-height: 55px;width: 150px;text-align: center;background: #2763ef;
        color: white;cursor: pointer;margin-left: 20px;
      }
    }
    .ulTwo{
      height: 64vh;overflow-y: auto;
    }
    .ulTwo li{
      height: 50px;align-items: center;
      display: flex;justify-content: space-between;
      font-size: 13px;border-bottom: 1px solid #f0efef;
    }
  }
  .diaImg{
  margin-top: -20px;
}
.diaImg .demo-tabs{
  width: 100%;
}
.diaImg li{
  width: 90%;display: flex;justify-content: flex-start;
  border: 1px solid #aaaaaa;border-radius: 5px;
  padding: 10px 4%;align-items: center;margin-top: 10px;
  cursor: pointer;
}
.diaImg li img{
  width: 50px;height: 50px;background-size: 100% 100%;
  margin-right: 10px;
}
}
.ulThree li{
  height: 50px;align-items: center;
  display: flex;justify-content: space-between;
  font-size: 13px;border-bottom: 1px solid #f0efef;
}
.ulThree li p:nth-child(2){
  background: #3c7cfb;padding: 5px 25px;
  color: white;border-radius: 5px;font-size: 13px;cursor: pointer;
}
.titleInfo{
  background: burlywood;color: white;font-size: 10px;
  padding: 3px;border-radius: 3px;margin-right: 5px;
}
.addNum{
  width: 100%;height: 50px;border: 1px solid red;
}
.diaImg{
  margin-top: -20px;
}
.diaImg .demo-tabs{
  width: 100%;
}
.diaImg li{
  width: 90%;display: flex;justify-content: flex-start;
  border: 1px solid #aaaaaa;border-radius: 5px;
  padding: 10px 4%;align-items: center;margin-top: 10px;
}
.diaImg li img{
  width: 50px;height: 50px;background-size: 100% 100%;
  margin-right: 10px;
}

</style>
